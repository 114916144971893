import { defineMessages } from "react-intl";

export const ecommerce = defineMessages({
  activeProducts: {
    id: "component.ecommerce.activeProducts",
    defaultMessage: "Prodotti attivi",
  },
  addProduct: {
    id: "component.ecommerce.addProduct",
    defaultMessage: "Aggiungi prodotto",
  },
  allProducts: {
    id: "component.ecommerce.allProducts",
    defaultMessage: "Tutti i prodotti",
  },
  burgers: {
    id: "component.ecommerce.burgers",
    defaultMessage: "Hamburger",
  },
  busy: {
    id: "component.ecommerce.busy",
    defaultMessage: "Occupato",
  },
  callList: {
    id: "component.ecommerce.callList",
    defaultMessage: "Storico chiamate",
  },
  callOutcome: {
    id: "component.ecommerce.callOutcome",
    defaultMessage: "Indica l'esito della chiamata",
  },
  callRecord: {
    id: "component.ecommerce.callRecord",
    defaultMessage: "Registra chiamata",
  },
  category: {
    id: "component.ecommerce.category",
    defaultMessage: "Categoria",
  },
  confirmed: {
    id: "component.ecommerce.confirmed",
    defaultMessage: "Confermata",
  },
  contacted: {
    id: "component.ecommerce.contacted",
    defaultMessage: "Contattato",
  },
  delayCollect: {
    id: "component.ecommerce.delayCollect",
    defaultMessage: "Posticipa l'orario di ritiro",
  },
  deleteOrder: {
    id: "component.ecommerce.deleteOrder",
    defaultMessage: "Cancella l'ordine",
  },
  deleted: {
    id: "component.ecommerce.deleted",
    defaultMessage: "Cancellato",
  },
  delivered: {
    id: "component.ecommerce.delivered",
    defaultMessage: "Consegnato",
  },
  displayPriority: {
    id: "component.ecommerce.displayPriority",
    defaultMessage: "Priorità di visualizzazione",
  },
  done: {
    id: "component.ecommerce.done",
    defaultMessage: "Eseguita",
  },
  drinks: {
    id: "component.ecommerce.drinks",
    defaultMessage: "Bevande",
  },
  dropped: {
    id: "component.ecommerce.dropped",
    defaultMessage: "Cade linea",
  },
  inactiveProducts: {
    id: "component.ecommerce.inactiveProducts",
    defaultMessage: "Prodotti inattivi",
  },
  inProgress: {
    id: "component.ecommerce.inProgress",
    defaultMessage: "In lavorazione",
  },
  item: {
    id: "component.ecommerce.item",
    defaultMessage: "Articolo",
  },
  newProduct: {
    id: "component.ecommerce.newProduct",
    defaultMessage: "Nuovo prodotto",
  },
  noAnswer: {
    id: "component.ecommerce.noAnswer",
    defaultMessage: "Non risponde",
  },
  notCollect: {
    id: "component.ecommerce.notCollect",
    defaultMessage: "Non ritirato",
  },
  openOrder: {
    id: "component.ecommerce.openOrder",
    defaultMessage: "ORDINI APERTI",
  },
  orderShort: {
    id: "component.ecommerce.orderShort",
    defaultMessage: "Ord.",
  },
  pause: {
    id: "component.ecommerce.pause",
    defaultMessage: "Pausa",
  },
  phoneContact: {
    id: "component.ecommerce.phoneContact",
    defaultMessage: "Contatta il cliente",
  },
  pizza: {
    id: "component.ecommerce.pizza",
    defaultMessage: "Pizza",
  },
  price: {
    id: "component.ecommerce.price",
    defaultMessage: "Prezzo",
  },
  products: {
    id: "component.ecommerce.products",
    defaultMessage: "Prodotti",
  },
  ready: {
    id: "component.ecommerce.ready",
    defaultMessage: "Pronto per il ritiro",
  },
  resume: {
    id: "component.ecommerce.resume",
    defaultMessage: "Riprendi",
  },
  sandwich: {
    id: "component.ecommerce.sandwich",
    defaultMessage: "Panini",
  },
  salads: {
    id: "component.ecommerce.salads",
    defaultMessage: "Insalate",
  },
  sides: {
    id: "component.ecommerce.sides",
    defaultMessage: "Contorni",
  },
  status: {
    id: "component.ecommerce.status",
    defaultMessage: "Stato",
  },
  statusBack: {
    id: "component.ecommerce.statusBack",
    defaultMessage: "Torna allo stato precedente",
  },
  statusNext: {
    id: "component.ecommerce.statusNext",
    defaultMessage: "Passa allo stato successivo",
  },
  tag: {
    id: "component.ecommerce.tag",
    defaultMessage: "Tag",
  },
  toConfirm: {
    id: "component.ecommerce.toConfirm",
    defaultMessage: "Da confermare",
  },
  toMake: {
    id: "component.ecommerce.toMake",
    defaultMessage: "Da preparare",
  },
  total: {
    id: "component.ecommerce.total",
    defaultMessage: "Totale",
  },
  unreachable: {
    id: "component.ecommerce.unreachable",
    defaultMessage: "Non raggiungibile",
  },
  visitReservation: {
    id: "ecommerce.visitReservation",
    defaultMessage: "Prenotazione della visita",
  },
  reservationsDone: {
    id: "ecommerce.reservationsCompleted",
    defaultMessage: "Prenotazioni effettuate",
  },
  youHaveNoReservations: {
    id: "ecommerce.youHaveNoReservations",
    defaultMessage: "Nessuna prenotazione.",
  },
  visitorType: {
    id: "ecommerce.visitorType",
    defaultMessage: "Tipo di visitatore",
  },
  visitorGroupName: {
    id: "ecommerce.visitorGroupName",
    defaultMessage: "Nome del gruppo",
  },
  participantsNumber: {
    id: "ecommerce.participantsNumber",
    defaultMessage: "Numero partecipanti",
  },
  visitLanguage: {
    id: "ecommerce.visitLanguage",
    defaultMessage: "Lingua della visita",
  },
  confirmDeleteReservation: {
    id: "ecommerce.confirmDeleteReservation",
    defaultMessage:
      "Stai per cancellare la prenotazione {name}. Confermi la cancellazione?",
  },
  askPhoneForReservation: {
    id: "ecommerce.askPhoneForReservation",
    defaultMessage:
      "Per prenotare una vista è necessario associare un numero di telefono al proprio profilo utente.",
  },
  reservationNotesInfo: {
    id: "ecommerce.reservationNotesInfo",
    defaultMessage:
      "Inserire qui le vostre note per specificare esigenze particolari o esigenze speciali di accessibilità.",
  },
  askConfirmNReservations: {
    id: "ecommerce.askConfirmNReservations",
    defaultMessage:
      "Stai per confermare {numReservations} prenotazioni. Procedi?",
  },
  askConfirm1Reservation: {
    id: "ecommerce.askConfirm1Reservation",
    defaultMessage: "Stai per confermare una prenotazione. Procedi?",
  },
  noSelectedReservationsToConfirm: {
    id: "ecommerce.noSelectedReservationsToConfirm",
    defaultMessage:
      "Nessuna delle prenotazioni che hai selezionato è da confermare",
  },
  askCancelNReservations: {
    id: "ecommerce.askConfirmNReservations",
    defaultMessage:
      "Stai per annullare {numReservations} prenotazioni. Procedi?",
  },
  askCancel1Reservation: {
    id: "ecommerce.askCancel1Reservation",
    defaultMessage: "Stai per annullare una prenotazione. Procedi?",
  },
  noSelectedReservationsToCancel: {
    id: "ecommerce.noSelectedReservationsToCancel",
    defaultMessage:
      "Nessuna delle prenotazioni che hai selezionato può essere annullata",
  },
  showPastReservations: {
    id: "ecommerce.showPastReservations",
    defaultMessage: "Mostra le prenotazioni passate",
  },
});
