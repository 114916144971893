import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  Link,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ArrowBack,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@material-ui/icons";

import Confirm from "../ui/Confirm";
import FloatingActions, { Action } from "../ui/FloatingActions";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import {
  BUSINESS_TYPES,
  FEATURES,
  MANAGER_ROUTES,
  USER_ROLES,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import {
  common,
  confirm,
  form,
  navigation,
  notifications,
  roles,
  storeTypes,
  subscriptions,
} from "../messages";
//import styles from "../utils/styles";
import PlanLimitAlert from "../ui/PlanLimitAlert";
import LimitProgress from "../ui/LimitProgress";
import { museumLabels } from "../messages/businessMuseums";
import { storeLabels } from "../messages/businessStores";
import { Language } from "../ui/forms/profile";
import { langs } from "../utils/dictionaries";
import { decodeFromArray } from "../utils/utils";
import MediaContentForm from "../pages/manager/MediaContentForm";
import ReactQuill from "react-quill";
//import parse from "html-react-parser";
import { Pagination } from "@material-ui/lab";
import AddressAutocomplete2 from "./AddressAutocomplete2";
import NumberInput from "../ui/NumberInput";
import ManageGenList from "./ManageGenList";
import { useHistory } from "react-router-dom";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const ProvinceList = lazy(() =>
  import(/* webpackChunkName: 'ProvinceList' */ "./ProvinceList.js")
);

export const rolesLabels = {
  ASSISTANT: roles.storeAssistant,
  BUSINESS_MANAGER: roles.storeManager,
  CASHIER: roles.operator,
  EDITOR: roles.campaignManager,
  REPORTER: roles.viewer,
  SUBSCRIPTION_MANAGER: roles.subscriptionManager,
  SUBSCRIPTION_PLAN_MANAGER: roles.subscriptionPlanManager,
  SUBSCRIPTION_STAMP_MANAGER: roles.subscriptionStampManager,
};

const INIT_QUERY = {
  page: 1,
  qty: 100,
  status: "",
  category: "",
  display: ["manager", "province", "category"],
  order: "order",
};

export const getPermName = (code, intl) =>
  intl.formatMessage(
    rolesLabels[Object.entries(USER_ROLES).find((r) => r[1] === code)[0]]
  );

function BusinessElement({
  business,
  //managers,
  onModify,
  onDelete,
  //status,
  update,
  businessLabels,
  setFilter,
  categories,
  gdspTenant,
}) {
  //const classes = styles.useStyles();
  const intl = useIntl();
  const classes = useRowStyles();
  const {
    bmapi,
    notifySuccess,
    notifyError,
    notifyDialog,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(USER_ROLES.BUSINESS_MANAGER);
  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteStoreRequest, setDeleteStoreRequest] = useState(false);
  const [showPermission, setShowPermission] = useState(false);

  const permToName = (code) => getPermName(code, intl);

  const handleDelete = () => {
    setDeleteStoreRequest(false);
    setLoading(true);
    setAnchorEl(null);
    onDelete(business.id);
  };

  const handleModify = () => {
    setAnchorEl(null);

    const getBusinessInfo =
      business.status !== 5
        ? bmapi.getBusiness(business.id)
        : bmapi.getDeletedBusiness(business.id);

    getBusinessInfo
      .then((bsInfo) => {
        let businessInfo = {
          ...business,
          ...bsInfo,
        };

        onModify(businessInfo);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleAddManager = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEmail("");
    setPassword("");
  };

  const handleRevokeManager = () => {
    setSaving(true);
    return bmapi
      .revokeUserPermission(deleteRequest.permission_id, deleteRequest.email)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerRemoved));
        setDeleteRequest(false);
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const createManager = (email, businessId, role, password) => {
    if (bmapi.settings?.manageManagerPassword) {
      return bmapi.createManagerWithCredentials(
        email,
        businessId,
        role,
        password
      );
    } else {
      return bmapi.createManager(email, businessId, role);
    }
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    setSaving(true);

    createManager(email.trim(), business.id, role, password.trim())
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerAdded));
        handleCloseDialog();
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortManager = (a, b) => {
    return permToName(a.permission) !== permToName(b.permission)
      ? permToName(a.permission).localeCompare(permToName(b.permission))
      : a.email.localeCompare(b.email);
  };

  const availableManagers = bmapi.subscription
    ? bmapi.subscription.plan_limits.max_managers_per_business -
      business?.managers?.length
    : 10000;

  const categoriesMap = new Map(
    categories?.map((obj) => [obj.id, obj.str_value])
  );

  const showPassword = async (m) => {
    startLoading();
    try {
      const resp = await bmapi.getManagerCredentialsByEmail(m.email);
      if (resp.password) {
        notifyDialog({ text: resp.password, title: "Password" });
      }
      stopLoading();
    } catch (e) {
      stopLoading(false);
      notifyError(getErrorMessageString(e, intl));
    }
  };

  return (
    <React.Fragment>
      {deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={handleRevokeManager}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteManager, deleteRequest)}
          flag
        />
      )}

      {deleteStoreRequest && (
        <Confirm
          open={!!deleteStoreRequest}
          onConfirm={handleDelete}
          onCancel={() => setDeleteStoreRequest(false)}
          text={intl.formatMessage(confirm.deleteStore, deleteStoreRequest)}
          flag
        />
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleCreateManager}>
          <DialogTitle>
            <FormattedMessage
              id="component.manageStores.addManager"
              defaultMessage="Aggiungi manager"
            />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.store)}
              value={business.name}
              disabled
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.email)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              fullWidth
            />
            {bmapi?.settings?.manageManagerPassword && (
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(common.password)}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                required
                fullWidth
              />
            )}
            <TextField
              margin="dense"
              label={intl.formatMessage(common.role)}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              fullWidth
              select
              disabled={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
              }
              helperText={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
                  ? intl.formatMessage(subscriptions.notAvailableinTrial)
                  : false
              }
            >
              {Object.entries(rolesLabels)
                .filter(([role]) => !role.startsWith("SUBSCRIPTION_"))
                .map(([role, label]) => [role, intl.formatMessage(label)])
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map(([role, label]) => (
                  <MenuItem value={USER_ROLES[role]} key={USER_ROLES[role]}>
                    {label}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              disabled={saving}
              variant="outlined"
            >
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {intl.formatMessage(common.create)}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      {/* NEW TABLE VIEW */}

      <TableBody>
        <TableRow className={classes.root}>
          <TableCell component="th" scope="row" style={{ padding: "6px 0" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setShowPermission(!showPermission)}
              disabled={!business?.managers.length}
            >
              {showPermission ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell padding="none">{business.name}</TableCell>

          <TableCell padding="none">
            {categoriesMap.get(
              business.attrs?.find((obj) => obj.k === "category").v
            ) || "-"}
          </TableCell>
          {gdspTenant && (
            <TableCell padding="none">
              {business.attrs?.find((obj) => obj.k === "province").v || "-"}
            </TableCell>
          )}
          <TableCell padding="none">
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilter("general");
              }}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{business.name}</Typography>
              </MenuItem>
              {business.status !== 5 && (
                <MenuItem
                  onClick={handleAddManager}
                  disabled={availableManagers < 1}
                >
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    <FormattedMessage
                      id="component.manageStores.addManager"
                      defaultMessage="Aggiungi manager"
                    />
                    {availableManagers < 1 && (
                      <small>
                        [{intl.formatMessage(subscriptions.noSlots)}]
                      </small>
                    )}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage {...businessLabels.editBusiness} />
                </Typography>
              </MenuItem>
              {business.status !== 5 && (
                <MenuItem onClick={() => setDeleteStoreRequest(business)}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    <FormattedMessage {...businessLabels.deleteBusiness} />
                  </Typography>
                </MenuItem>
              )}
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={showPermission} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {business?.managers?.sort(alphaSortManager).map((m) => (
                  <Typography variant="body2" key={m.email}>
                    <strong>{permToName(m.permission)}</strong>:{" "}
                    {m.is_registered ? `${m.email} ✅ ` : <i>[{m.email}] </i>}
                    {bmapi.settings.manageManagerPassword && (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => showPassword(m)}
                      >
                        PASSWORD
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(m)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>

      {/*       <ListItem>
        <ListItemText
          primary={
            <React.Fragment>
              {business.name}{" "}
              {business.demo && (
                <Chip
                  component="span"
                  label="demo"
                  size="small"
                  className={classes.demoChip}
                />
              )}
            </React.Fragment>
          }
          primaryTypographyProps={{ variant: "h6" }}
          secondary={
            <>
              Categoria: {business.attrs?.find((obj) => obj.k === "category").v}
              <br />
              Provincia: {business.attrs?.find((obj) => obj.k === "province").v}
            </>
          }
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilter("general");
              }}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{business.name}</Typography>
              </MenuItem>
              {business.status !== 5 && (
                <MenuItem
                  onClick={handleAddManager}
                  disabled={availableManagers < 1}
                >
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    <FormattedMessage
                      id="component.manageStores.addManager"
                      defaultMessage="Aggiungi manager"
                    />
                    {availableManagers < 1 && (
                      <small>
                        {" "}
                        [{intl.formatMessage(subscriptions.noSlots)}]
                      </small>
                    )}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage {...businessLabels.editBusiness} />
                </Typography>
              </MenuItem>
              {business.status !== 5 && (
                <MenuItem onClick={() => setDeleteStoreRequest(business)}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    <FormattedMessage
                      id="component.manageStores.deleteStore"
                      defaultMessage="Elimina negozio"
                    />
                  </Typography>
                </MenuItem>
              )}
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>*/}

      {/* {!!business?.managers.length && (
        <ListItem>
          <ListItemText
            primary={
              <React.Fragment>
                {business?.managers?.sort(alphaSortManager).map((m) => (
                  <Typography variant="body2" key={m.email}>
                    <strong>{permToName(m.permission)}</strong>:{" "}
                    {m.is_registered ? `${m.email} ✅ ` : <i>[{m.email}] </i>}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(m)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </ListItem>
      )}*/}
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => {
  return {
    status: bs.status || 0,
    type: bs.type || BUSINESS_TYPES.MERCHANT,
    business_group_id: bs.business_group_id || "DEFAULT",
    category: bs.category || "",
    competence_locations: bs.competence_locations || [],
    demo: bs.demo || false,
    description: bs.description || "",
    independent: false,
    indexed: bs.indexed || false,
    location: bs.location || {},
    manager_email: bs.manager_email || "",
    manager_name: bs.manager_name || "",
    media_contents: bs.media_contents || [],
    mobile: bs.mobile || "",
    name: bs.name || "",
    priority: bs.priority || "",
    vat_number: bs.vat_number || "",
    web_url: bs.web_url || "",
    default_language: bs.default_language || "",
    available_languages: bs.available_languages || [],
    private: !!bs.private,
    show_location: !!bs.show_location,
    show_activities: !!bs.show_activities,
    show_outdoor: !!bs.show_outdoor,
    show_tour_transports: !!bs.show_tour_transports,
    show_tour_prices: !!bs.show_tour_prices,
    whatsapp_phone: bs.whatsapp_phone || "",
    search_by_location: bs.search_by_location || false,
    search_by_qrcode: bs.search_by_qrcode || false,
    ecommerce_ordering: bs.ecommerce_ordering || false,
  };
};

export default function ManageStores({ type }) {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  //const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [open, setOpen] = useState(false);
  const [mediaContentValues, setMediaContentValues] = useState(null);
  const [values, setValues] = useState(initialValues({ type }));
  const [categories, setCategories] = useState(false);
  /*const [valueDescriptionRichEditor, setValueDescriptionRichEditor] = useState(
    ""
  );*/
  //const [status, setStatus] = useState({});
  const [filter, setFilter] = useState("general");
  const [total, setTotal] = useState(false);
  const [query, setQuery] = useState(INIT_QUERY);

  let provincesList = require(`../configurations/address/provinces-ita.json`);

  const gdspTenant = bmapi.settings.tenantId === "6294348160712791";

  const businessLabels =
    type && type === BUSINESS_TYPES.MUSEUM ? museumLabels : storeLabels;

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      if (label === "priority") {
        setValues((v) => ({ ...v, [label]: val.slice(0, 5) }));
      } else setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleQuery = useCallback((label) => {
    const updateValue = (val) => {
      setQuery((v) => ({ ...v, [label]: val }));
    };
    return (i, f) => {
      if (label === "page" || label === "status") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleAddressSelected = (addr) => {
    const newValues = { ...values, location: addr };
    setValues(newValues);
  };

  const handleLanguage = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
    },
    []
  );

  const handleDefaultLanguage = (e) => {
    const val = e.target.value;
    const newValues = { ...values };
    const index = values.available_languages.indexOf(val);
    if (index > -1) {
      const newLang = [...values.available_languages];
      newLang.splice(index, 1);
      newValues.available_languages = newLang;
    }
    newValues.default_language = val;
    setValues(newValues);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const updateBusinesses = useCallback(
    (bs /*businessId*/) => {
      let bsFiltered =
        (bs || []).filter(
          (b) =>
            type === b.type || (!type && b.type === BUSINESS_TYPES.MERCHANT) //&&
          //b.status === 0
        ) || [];
      /*const businessMediaUpdate = bsFiltered
        .map((bs) => bs)
        .find((bs) => bs.id === businessId);*/
      // remove type loop - requires arr
      setBusinesses(bsFiltered);
      // update values / update media_contents - requires obj
      //setValues(initialValues(businessMediaUpdate || bsFiltered[0]));
    },
    [type]
  );

  const handleDelete = async (id) => {
    try {
      const q = adjustQuery(query);
      await bmapi.deleteBusiness(id);
      const count = await bmapi.getBusinessesCount({
        ...q,
        subscription: bmapi.getUserInfo().business.subscription_id,
      });
      const businesses = await bmapi.getBusinessesAdmin({
        ...q,
        subscription: bmapi.getUserInfo().business.subscription_id,
      });
      setTotal({ count: count });
      updateBusinesses(businesses);
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    } finally {
      clear();
      setLoading(false);
    }
  };

  const handleModify = (business) => {
    setValues(initialValues(business));
    setCurrentId(business.id);
    setOpenDialog(true);
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
    //setQuery(INIT_QUERY);
  };

  const adjustQuery = (q) => ({
    ...q,
    page: q.page - 1,
    // q.page - 1 evita che ultima pagina sia vuota, perché parte da 0
    status: q.status ? 5 : 0,
  });

  const handleLoad = useCallback(
    async (query) => {
      try {
        const q = adjustQuery(query);
        setLoading(true);
        const count = await bmapi.getBusinessesCount({
          ...q,
          subscription: bmapi.getUserInfo().business.subscription_id,
        });
        const businesses = await bmapi.getBusinessesAdmin({
          ...q,
          subscription: bmapi.getUserInfo().business.subscription_id,
        });
        setTotal({ count: count });
        updateBusinesses(businesses);
        /*
        if (loadManagers) {
          const ps = await bmapi.getManagers();
          setPermissions(ps);
        }
        */
        setLoading(false);
      } catch (e) {
        setLoading(false);
        notifyError(getErrorMessageString(e, intl));
      }
    },
    [bmapi, intl, notifyError, updateBusinesses]
  );

  const loadCategories = useCallback(() => {
    bmapi
      .getGenListCategories()
      .then((resp) => {
        if (!resp) {
          resp = [];
        }
        resp.items.sort((a, b) => a.str_value.localeCompare(b.str_value));

        setCategories(resp);
      })
      .catch((e) => {
        if (
          e.httpCode === 404 &&
          getErrorMessageString(e) === "Entity not found"
        ) {
          return;
        }
        notifyError(getErrorMessageString(e, intl));
      });
  }, [bmapi, intl, notifyError]);

  const handleCreate = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const q = adjustQuery(query);
      await bmapi.saveBusiness(
        {
          ...values,
          location: { ...values.location, city: values.location.city?.trim() },
          priority: +values.priority,
        },
        //{ ...values },
        currentId
      );
      const count = await bmapi.getBusinessesCount({
        ...q,
        subscription: bmapi.getUserInfo().business.subscription_id,
      });
      const businesses = await bmapi.getBusinessesAdmin({
        ...q,
        subscription: bmapi.getUserInfo().business.subscription_id,
      });
      //const ps = await bmapi.getManagers();
      setTotal({ count: count });
      updateBusinesses(businesses);
      handleLoad();
      notifySuccess(
        intl.formatMessage({
          id: "component.manageStores.storeSaved",
          defaultMessage: "Negozio salvato con successo",
        })
      );
      //setPermissions(ps);
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
      /*notifySuccess(
        intl.formatMessage({
          id: "component.manageStores.storeSaved",
          defaultMessage: "Negozio salvato con successo",
        })
      );*/
    } finally {
      clear();
      setSaving(false);
    }
  };

  const byName = (a, b) => a.name.localeCompare(b.name);

  /*const checkPostalCodeIT = (value) => {
    return !isNaN(parseFloat(value)) && value.length === 5;
  };*/

  const update = useCallback(
    async (businessId) => {
      setLoading(true);
      try {
        const q = adjustQuery(query);
        const businesses = await bmapi.getBusinessesAdmin({
          ...q,
          subscription: bmapi.getUserInfo().business.subscription_id,
        });
        updateBusinesses(businesses, businessId);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        notifyError(getErrorMessageString(e, intl));
      }
    },
    [bmapi, intl, notifyError, updateBusinesses, query]
  );

  const updateMedia = () => {
    bmapi
      .getBusiness(currentId)
      .then((bsInfo) => {
        setValues(initialValues(bsInfo));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  useEffect(() => {
    handleLoad(INIT_QUERY);
    loadCategories(); // replace bmapi.settings.businessCategories
  }, [handleLoad, loadCategories]);
  /*
  useEffect(() => {
    if (permissions) {
      permissions.forEach((perm) =>
        setStatus((st) =>
          st[perm.email]
            ? st
            : {
                ...st,
                [perm.email]: bmapi
                  .checkManagerRegistration(perm.id)
                  .then((res) =>
                    setStatus((s) => ({ ...s, [perm.email]: res }))
                  )
                  .catch(console.error),
              }
        )
      );
    }
  }, [bmapi, intl, notifyError, permissions]);
  */

  const createLink = (str) => (
    <Link onClick={() => setOpenDialog(true)}>{str}</Link>
  );

  const limitReached =
    bmapi.subscription &&
    bmapi.subscription.plan_limits.max_business > 0 &&
    bmapi.subscription.plan_limits.max_business <= businesses.length;

  const deleteLanguage = (lang) => () => {
    const index = values.available_languages.indexOf(lang);
    if (index > -1) {
      const newLang = [...values.available_languages];
      newLang.splice(index, 1);
      setValues({ ...values, available_languages: newLang });
    }
  };

  const deleteFromArray = (field, value) => () => {
    let newArr = [];
    values[field].map((item) => {
      if (item !== value) {
        newArr.push(item);
      }
    });
    setValues((v) => ({
      ...v,
      [field]: newArr,
    }));
  };

  const submitQuery = (e) => {
    e.preventDefault();
    let provinceArr = [];
    if (query.region) {
      provincesList.map((obj) =>
        obj.regione === query.region ? provinceArr.push(obj.sigla) : null
      );
    }
    query.prov = provinceArr;
    setQuery((v) => ({ ...v, page: 0 }));
    handleLoad({ ...query, page: 0 });
  };

  const changePage = (goTop) => (_, val) => {
    setQuery((v) => ({ ...v, page: val }));
    handleLoad({ ...query, page: val });
    if (goTop) window.scrollTo(0, 0);
  };

  return (
    <Container maxWidth="md">
      <Title>{intl.formatMessage(businessLabels.pageTitle)}</Title>
      {bmapi.subscription &&
        bmapi.subscription.plan_limits.max_business > 0 && (
          <LimitProgress
            value={businesses?.length || 0}
            limit={bmapi.subscription.plan_limits.max_business}
          />
        )}
      {(!businesses || loading) && <LinearProgress />}
      <Dialog
        open={openDialog && (!limitReached || currentId)}
        //onClose={clear}
        fullWidth
        PaperProps={{
          style: {
            margin: 10,
            width: "100vw",
          },
        }}
      >
        <DialogTitle>
          {currentId ? (
            <FormattedMessage {...businessLabels.editBusiness} />
          ) : (
            <FormattedMessage {...businessLabels.createBusiness} />
          )}
        </DialogTitle>
        <DialogContent style={{ padding: filter === "general" ? 15 : 2 }}>
          <Tabs
            value={filter}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={(_, v) => setFilter(v)}
          >
            <Tab
              label={intl.formatMessage(common.generalData)}
              value="general"
              style={{ minWidth: 0 }}
            />
            {currentId && (
              <Tab
                label={intl.formatMessage(common.multimediaData)}
                value="multimedia"
                style={{ minWidth: 0 }}
              />
            )}
          </Tabs>
          {filter === "general" && (
            <React.Fragment>
              <form onSubmit={handleCreate}>
                <TextField
                  autoFocus
                  margin="normal"
                  label={intl.formatMessage(form.name)}
                  value={values.name}
                  onChange={handleValue("name")}
                  required
                  fullWidth
                />
                <InputLabel style={{ marginTop: 15 }}>
                  {intl.formatMessage(form.description)}
                </InputLabel>
                <FormControl fullWidth margin="dense">
                  <ReactQuill
                    onChange={handleValue("description")}
                    placeholder="Inserire la descrizione del negozio"
                    value={values.description}
                    theme="snow"
                  />
                </FormControl>

                {(!type || type !== BUSINESS_TYPES.MUSEUM) && (
                  <Box display="flex" mt={2}>
                    <TextField
                      margin="dense"
                      label={intl.formatMessage(form.category)}
                      value={values.category}
                      onChange={handleValue("category")}
                      fullWidth
                      select={!!categories.items}
                    >
                      {!!categories.items && (
                        <MenuItem value="">
                          {intl.formatMessage(form.noCategory)}
                        </MenuItem>
                      )}
                      {(categories.items || []).map((category) => (
                        <MenuItem value={category.id} key={category.id}>
                          {storeTypes[category.str_value]
                            ? intl.formatMessage(storeTypes[category.str_value])
                            : category.str_value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ marginTop: 15 }}
                      >
                        Gestione
                      </Button>
                    </Box>
                  </Box>
                )}

                <TextField
                  margin="dense"
                  label="Nome del Responsabile"
                  value={values.manager_name}
                  onChange={handleValue("manager_name")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label={intl.formatMessage(form.email)}
                  value={values.manager_email}
                  onChange={handleValue("manager_email")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label={intl.formatMessage(form.mobile)}
                  value={values.mobile}
                  onChange={handleValue("mobile")}
                  fullWidth
                  inputProps={{ maxLength: 12 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                />
                <TextField
                  margin="dense"
                  label="Numero WhatsApp"
                  value={values.whatsapp_phone}
                  onChange={handleValue("whatsapp_phone")}
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                />
                <TextField
                  margin="dense"
                  label="Web Url"
                  value={values.web_url}
                  onChange={handleValue("web_url")}
                  fullWidth
                />

                <Suspense
                  fallback={<div>...{intl.formatMessage(common.loading)}</div>}
                >
                  <ProvinceList
                    province={values.competence_locations}
                    handleValue={handleValue("competence_locations")}
                    margin="dense"
                    label="Province di competenza"
                    deleteFromArray={deleteFromArray}
                    name="competence_locations"
                    multiple={true}
                  />
                </Suspense>

                {/* 
                <TextField
                  value={values.lat}
                  label={intl.formatMessage(form.latitude)}
                  fullWidth
                  onChange={handleValue("lat")}
                  type="number"
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  margin="dense"
                />
                <TextField
                  value={values.lng}
                  label={intl.formatMessage(form.longitude)}
                  fullWidth
                  onChange={handleValue("lng")}
                  type="number"
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  margin="dense"
                /> */}

                {(!type || type !== BUSINESS_TYPES.MUSEUM) && (
                  <NumberInput
                    float
                    name="priority"
                    label="Priorità"
                    value={values.priority}
                    onChange={handleValue("priority")}
                    fullWidth
                    margin="dense"
                  />
                )}

                <Box mt={2}>
                  <Typography variant="h6">Indirizzo</Typography>
                  <AddressAutocomplete2
                    googlePlacesKey="AIzaSyDmTGCjqNH942kSPubb1OblDbDsT2YaIrI"
                    types="address"
                    onChange={handleAddressSelected}
                    address={values.location}
                    margin="dense"
                  />
                </Box>

                {type === BUSINESS_TYPES.MUSEUM && (
                  <>
                    <Language
                      value={values.default_language}
                      onChange={handleDefaultLanguage}
                      label={intl.formatMessage(form.defaultLanguage)}
                      required
                      excludesAutomatic={true}
                    />
                    <FormControl fullWidth>
                      <InputLabel>
                        {intl.formatMessage(form.otherSupportedLanguages)}
                      </InputLabel>
                      <Select
                        multiple
                        fullWidth
                        value={values.available_languages}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                          },
                        }}
                        onChange={handleLanguage("available_languages")}
                        input={<Input />}
                        renderValue={(selected) => (
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((lang) => (
                              <Chip
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={deleteLanguage(lang)}
                                key={lang}
                                label={decodeFromArray(langs, lang)}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {langs
                          .filter(
                            (l) =>
                              bmapi.settings.languages.includes(l.value) &&
                              l.value !== values.default_language
                          )
                          .map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      margin="normal"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.search_by_location}
                            color="primary"
                            onChange={handleValue("search_by_location")}
                            key="search_by_location"
                            name="search_by_location"
                          />
                        }
                        label={intl.formatMessage(form.searchByLocation)}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.search_by_qrcode}
                            color="primary"
                            onChange={handleValue("search_by_qrcode")}
                            key="search_by_qrcode"
                            name="search_by_qrcode"
                          />
                        }
                        label={intl.formatMessage(form.searchByQrCode)}
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.ecommerce_ordering}
                            color="primary"
                            onChange={handleValue("ecommerce_ordering")}
                            key="ecommerce_ordering"
                            name="ecommerce_ordering"
                          />
                        }
                        label={intl.formatMessage(navigation.reservations)}
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.demo}
                            color="primary"
                            onChange={handleValue("demo")}
                            key="demo"
                            name="demo"
                          />
                        }
                        label={intl.formatMessage(form.demoStore)}
                      />
                    </FormControl>
                    <FormControl
                      style={{
                        border: "solid 1px grey",
                        padding: "10px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.private}
                            color="primary"
                            onChange={handleValue("private")}
                            key="private"
                            name="private"
                          />
                        }
                        label={intl.formatMessage(form.withAuthentication, {
                          entity: "Business",
                        })}
                      />
                      {intl.formatMessage(form.messageAuthentication, {
                        entity: "business",
                      })}
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <Typography variant="h6">
                        {intl.formatMessage(form.optionalPoiFields)}
                      </Typography>
                    </FormControl>
                    <FormControl
                      margin="normal"
                      fullWidth
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.show_location}
                            color="primary"
                            onChange={handleValue("show_location")}
                            key="show_location"
                            name="show_location"
                          />
                        }
                        label={intl.formatMessage(form.withLocation)}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.show_activities}
                            color="primary"
                            onChange={handleValue("show_activities")}
                            key="show_activities"
                            name="show_activities"
                          />
                        }
                        label={intl.formatMessage(form.withActivityType)}
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      fullWidth
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.show_outdoor}
                            color="primary"
                            onChange={handleValue("show_outdoor")}
                            key="show_outdoor"
                            name="show_outdoor"
                          />
                        }
                        label={intl.formatMessage(form.withOutdoor)}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.show_tour_transports}
                            color="primary"
                            onChange={handleValue("show_tour_transports")}
                            key="show_tour_transports"
                            name="show_tour_transports"
                          />
                        }
                        label={intl.formatMessage(form.withTourTransport)}
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      fullWidth
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.show_tour_prices}
                            color="primary"
                            onChange={handleValue("show_tour_prices")}
                            key="show_tour_prices"
                            name="show_tour_prices"
                          />
                        }
                        label={intl.formatMessage(form.withTourPrices)}
                      />
                    </FormControl>
                  </>
                )}
                <TextField
                  margin="dense"
                  label={intl.formatMessage(common.status)}
                  value={values.status}
                  onChange={handleValue("status")}
                  required
                  fullWidth
                  select
                >
                  <MenuItem value={0}>
                    {intl.formatMessage(common.active)}
                  </MenuItem>
                  {!bmapi.can(FEATURES.HIDE_STORE_PARAMS) && (
                    <MenuItem value={1}>
                      {intl.formatMessage(common.deactivated)}
                    </MenuItem>
                  )}
                  <MenuItem value={5}>
                    {intl.formatMessage(common.deleted)}
                  </MenuItem>
                </TextField>
                {(!type || type !== BUSINESS_TYPES.MUSEUM) && (
                  <FormControl margin="normal" fullWidth>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.indexed}
                          color="primary"
                          onChange={handleValue("indexed")}
                          key="indexed"
                          name="indexed"
                        />
                      }
                      label="Pubblicato"
                    />
                    {!bmapi.can(FEATURES.HIDE_STORE_PARAMS) && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.demo}
                            color="primary"
                            onChange={handleValue("demo")}
                            key="demo"
                            name="demo"
                          />
                        }
                        label={intl.formatMessage(form.demoStore)}
                      />
                    )}
                  </FormControl>
                )}
                <DialogActions>
                  <Button onClick={clear} disabled={saving} variant="outlined">
                    <FormattedMessage
                      id="common.cancel"
                      defaultMessage="Annulla"
                    />
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={saving}
                  >
                    {currentId ? (
                      <FormattedMessage
                        id="common.save"
                        defaultMessage="Salva"
                      />
                    ) : (
                      <FormattedMessage
                        id="common.create"
                        defaultMessage="Crea"
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </React.Fragment>
          )}
          {filter === "multimedia" && (
            <>
              <MediaContentForm
                media_contents={values.media_contents}
                setMediaContentValues={setMediaContentValues}
                mediaContentValues={mediaContentValues}
                id={currentId}
                //loadData={update}
                loadData={updateMedia}
                availableLanguages={[]}
                defaultLanguage={values.default_language}
                entity_type="Business"
                available_types={["image", "video"]}
                subtypes={bmapi.settings.mcBusinessImageSubtypes}
              />
              <DialogActions>
                <Button onClick={clear} disabled={saving}>
                  <FormattedMessage id="common.close" defaultMessage="Chiudi" />
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
        {saving && <LinearProgress />}
      </Dialog>

      <PlanLimitAlert
        open={!!(openDialog && limitReached && !currentId)}
        onClose={clear}
      />
      <Box mb={2}>
        <Card>
          <CardContent>
            <form onSubmit={submitQuery}>
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(common.name)}
                value={query.name}
                onChange={handleQuery("name")}
                fullWidth
              />
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(form.category)}
                value={query.category}
                onChange={handleQuery("category")}
                fullWidth
                select={!!categories.items}
              >
                {!!categories.items && (
                  <MenuItem value="">
                    {intl.formatMessage(form.noCategory)}
                  </MenuItem>
                )}
                {(categories.items || []).map((category) => (
                  <MenuItem value={category.id} key={category.str_value}>
                    {storeTypes[category.str_value]
                      ? intl.formatMessage(storeTypes[category.str_value])
                      : category.str_value}
                  </MenuItem>
                ))}
              </TextField>
              {gdspTenant && (
                <Suspense
                  fallback={<div>...{intl.formatMessage(common.loading)}</div>}
                >
                  <ProvinceList
                    region={query.region}
                    handleValue={handleQuery("region")}
                    margin="dense"
                    label="Regione di appartenenza"
                    name="region"
                  />
                </Suspense>
              )}
              <FormControl margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!query.status}
                      color="primary"
                      onChange={handleQuery("status")}
                      key="status"
                      name="status"
                    />
                  }
                  label={intl.formatMessage(form.showDeleted)}
                />
              </FormControl>
              <Box mt={1}>
                <FormControl
                  margin="normal"
                  style={{ float: "right", display: "block" }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    {intl.formatMessage(common.update)}
                  </Button>
                </FormControl>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
      {businesses && (
        <Box my={2}>
          <Button onClick={goToHome} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.backHome)}
          </Button>
        </Box>
      )}
      <Box mb={2}>
        {businesses &&
          (!businesses.length ? (
            <Card>
              <CardContent>
                <Typography gutterBottom>
                  <FormattedMessage {...businessLabels.noBusinessRegistered} />
                </Typography>
                <Typography>
                  <FormattedMessage
                    {...businessLabels.emptyAction}
                    values={{ link: createLink }}
                  />
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Card>
              <CardContent>
                {total?.count > INIT_QUERY.qty && (
                  <Box mt={3}>
                    <Pagination
                      count={Math.ceil(total?.count / query?.qty)}
                      page={query.page}
                      onChange={changePage(false)}
                      size="small"
                    />
                  </Box>
                )}
                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Categoria</TableCell>
                        {gdspTenant && <TableCell>Provincia</TableCell>}
                        {/*<TableCell>Utenti abilitati</TableCell>*/}
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {businesses
                      /*.filter((b) =>
                      query.status ? b.status === 5 : b.status !== 5
                    )*/
                      .sort(byName)
                      .map((b) => (
                        <React.Fragment key={b.id}>
                          <BusinessElement
                            business={b}
                            /*
                        managers={(permissions || []).filter(
                          (p) => p.resource_id === b.id
                        )}*/
                            //managers={[]}
                            onDelete={handleDelete}
                            onModify={handleModify}
                            //status={status}
                            update={update}
                            businessLabels={businessLabels}
                            setFilter={setFilter}
                            categories={categories.items}
                            gdspTenant={gdspTenant}
                          />
                        </React.Fragment>
                      ))}
                  </Table>
                  {total?.count > INIT_QUERY.qty && (
                    <Pagination
                      count={Math.ceil(total?.count / query?.qty)}
                      page={query.page}
                      onChange={changePage(true)}
                      size="small"
                    />
                  )}
                </TableContainer>
              </CardContent>
            </Card>
          ))}

        {open && (
          <ManageGenList
            loadData={loadCategories}
            open={open}
            setOpen={setOpen}
            title="Gestione categorie"
          />
        )}

        {businesses && (
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        )}

        <FloatingActions>
          <Action
            icon={<Add />}
            label={intl.formatMessage(businessLabels.createBusiness)}
            action={() => setOpenDialog(true)}
          />
        </FloatingActions>
      </Box>
    </Container>
  );
}
