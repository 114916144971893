import { defineMessages } from "react-intl";

export const wikiMuseo = defineMessages({
  accessibility: {
    id: "component.wikiMuseo.accessibility",
    defaultMessage: "Accessibilità",
  },
  accessibilityDescription: {
    id: "component.wikiMuseo.accessibilityDescription",
    defaultMessage: "Descrizione accessibilità",
  },
  accessibilityUrl: {
    id: "component.wikiMuseo.accessibilityUrl",
    defaultMessage: "Scheda accessibilità",
  },
  activityCultural: {
    id: "component.wikiMuseo.activityCultural",
    defaultMessage: "Contenuti culturali",
  },
  activityFood_Bev: {
    id: "component.wikiMuseo.activityFood_Bev",
    defaultMessage: "Degustazioni",
  },
  activityNature: {
    id: "component.wikiMuseo.activityNature",
    defaultMessage: "Paesaggi naturali",
  },
  activityRecreational: {
    id: "component.wikiMuseo.activityRecreational",
    defaultMessage: "Attività ludiche",
  },
  activityType: {
    id: "component.wikiMuseo.activityType",
    defaultMessage: "Tipo di attività",
  },
  audioDescription: {
    id: "component.wikiMuseo.audioDescription",
    defaultMessage: "Audiodescrizione per persone con disabilità visiva",
  },
  availableLanguages: {
    id: "component.wikiMuseo.availableLanguages",
    defaultMessage: "Lingue supportate",
  },
  availableTours: {
    id: "component.wikiMuseo.availableTours",
    defaultMessage: "Percorsi disponibili",
  },
  type: {
    id: "component.wikiMuseo.type",
    defaultMessage: "Tipo",
  },
  add3DModel: {
    id: "component.wikiMuseo.add3DModel",
    defaultMessage: "Aggiungi modello 3D",
  },
  addAudio: {
    id: "component.wikiMuseo.addAudio",
    defaultMessage: "Aggiungi audio",
  },
  addImage: {
    id: "component.wikiMuseo.addImage",
    defaultMessage: "Aggiungi immagine",
  },
  addMap: {
    id: "component.wikiMuseo.addMap",
    defaultMessage: "Aggiungi mappa",
  },
  addVideo: {
    id: "component.wikiMuseo.addVideo",
    defaultMessage: "Aggiungi video",
  },
  addExternalContent: {
    id: "component.wikiMuseo.addExternalContent",
    defaultMessage: "Aggiungi Contenuto Esterno",
  },
  aged: {
    id: "component.wikiMuseo.aged",
    defaultMessage: "Esigenze famigliari / anziani",
  },
  answers: {
    id: "component.wikiMuseo.answers",
    defaultMessage: "Risposte",
  },
  answerAdd: {
    id: "component.wikiMuseo.answerAdd",
    defaultMessage: "Aggiungi risposta",
  },
  answerCorrect: {
    id: "component.wikiMuseo.answerCorrect",
    defaultMessage: "Seleziona la risposta corretta",
  },
  answerTest: {
    id: "component.wikiMuseo.answerTest",
    defaultMessage: "Rispondi al test per poter avanzare",
  },
  audio: {
    id: "component.wikiMuseo.audio",
    defaultMessage: "Audio",
  },
  audioGallery: {
    id: "component.wikiMuseo.audioGallery",
    defaultMessage: "Galleria Audio",
  },
  autism: {
    id: "component.wikiMuseo.autism",
    defaultMessage: "Autismo",
  },
  quizAuth: {
    id: "component.wikiMuseo.quizAuth",
    defaultMessage: "E' necessario essere autenticati per rispondere.",
  },
  captionIssue: {
    id: "component.wikiMuseo.captionIssue",
    defaultMessage: "• Didascalia parziale/errata",
  },
  category: {
    id: "component.wikiMuseo.category",
    defaultMessage: "Categoria",
  },
  complexLanguage: {
    id: "component.wikiMuseo.complexLanguage",
    defaultMessage: "• Linguaggio complesso",
  },
  consent: {
    id: "component.wikiMuseo.consent",
    defaultMessage: "Clicca qui per fornire il consenso",
  },
  noCrop: {
    id: "component.wikiMuseo.noCrop",
    defaultMessage: "Ritaglio automatico",
  },
  cropTour: {
    id: "component.wikiMuseo.cropTour",
    defaultMessage:
      "Il ritaglio immagine verrà utilizzato nella lista dei percorsi",
  },
  cropPoi: {
    id: "component.wikiMuseo.cropPoi",
    defaultMessage:
      "Il ritaglio immagine verrà utilizzato nella lista dei punti di interesse di un percorso",
  },
  cropBottom: {
    id: "component.wikiMuseo.cropBottom",
    defaultMessage: "Allinea in basso",
  },
  cropCenter: {
    id: "component.wikiMuseo.cropCenter",
    defaultMessage: "Allinea al centro",
  },
  cropContain: {
    id: "component.wikiMuseo.cropContain",
    defaultMessage: "Adatta orizzontalmente",
  },
  cropDefault: {
    id: "component.wikiMuseo.cropDefault",
    defaultMessage: "Default",
  },
  cropLeft: {
    id: "component.wikiMuseo.cropLeft",
    defaultMessage: "Allinea a sinistra",
  },
  cropRight: {
    id: "component.wikiMuseo.cropRight",
    defaultMessage: "Allinea a destra",
  },
  cropTop: {
    id: "component.wikiMuseo.cropTop",
    defaultMessage: "Allinea in alto",
  },
  description: {
    id: "component.wikiMuseo.description",
    defaultMessage: "Descrizione",
  },
  descriptionIssue: {
    id: "component.wikiMuseo.descriptionIssue",
    defaultMessage: "• Descrizione non pertinente",
  },
  disabilityCognitive: {
    id: "component.wikiMuseo.disabilityCognitive",
    defaultMessage: "Disabilità cognitiva",
  },
  disabilityHearing: {
    id: "component.wikiMuseo.disabilityHearing",
    defaultMessage: "Disabilità sensoriale uditiva",
  },
  disabilityPhysical: {
    id: "component.wikiMuseo.disabilityPhysical",
    defaultMessage: "Disabilità motoria",
  },
  disabilityType: {
    id: "component.wikiMuseo.disabilityType",
    defaultMessage: "Adatto a",
  },
  disabilityVisual: {
    id: "component.wikiMuseo.disabilityVisual",
    defaultMessage: "Disabilità sensoriale visiva",
  },
  disabilityIpoVision: {
    id: "component.wikiMuseo.disabilityIpoVision",
    defaultMessage: "Disabilità sensoriale ipovisiva",
  },
  disabilityDeafSigning: {
    id: "component.wikiMuseo.disabilityDeafSigning",
    defaultMessage: "Disabilità sordi segnanti",
  },
  duration: {
    id: "component.wikiMuseo.duration",
    defaultMessage: "Durata percorso",
  },
  durationDays: {
    id: "component.wikiMuseo.durationDays",
    defaultMessage: "{days} giorni, ore {hours} e {minutes} minuti",
  },
  durationHours: {
    id: "component.wikiMuseo.durationHours",
    defaultMessage: "{hours} ore",
  },
  durationHoursMinutes: {
    id: "component.wikiMuseo.durationHoursMinutes",
    defaultMessage: "{hours} ore e {minutes} minuti",
  },
  durationMinutes: {
    id: "component.wikiMuseo.durationMinutes",
    defaultMessage: "{minutes} minuti",
  },
  endTour: {
    id: "component.wikiMuseo.endTour",
    defaultMessage: "Fine del percorso",
  },
  externalContent: {
    id: "component.wikiMuseo.externalContent",
    defaultMessage: "Contenuti esterni",
  },
  facilitatorGuide: {
    id: "component.wikiMuseo.facilitatorGuide",
    defaultMessage: "Guida turistica facilitatore del turismo accessibile",
  },
  facilitatorCompanion: {
    id: "component.wikiMuseo.facilitatorCompanion",
    defaultMessage:
      "Accompagnatore turistico facilitatore del turismo accessibile",
  },
  file3D: {
    id: "component.wikiMuseo.file3D",
    defaultMessage: "Modello 3D",
  },
  fileAudio: {
    id: "component.wikiMuseo.fileAudio",
    defaultMessage: "File audio",
  },
  fileImage: {
    id: "component.wikiMuseo.fileImage",
    defaultMessage: "File immagine",
  },
  fileMap: {
    id: "component.wikiMuseo.fileMap",
    defaultMessage: "Mappa",
  },
  fileVideo: {
    id: "component.wikiMuseo.fileVideo",
    defaultMessage: "File video",
  },
  from: {
    id: "component.wikiMuseo.from",
    defaultMessage: "Dal",
  },
  glossary: {
    id: "component.wikiMuseo.glossary",
    defaultMessage: "Glossario",
  },
  glossaryAddName: {
    id: "component.wikiMuseo.glossaryAddName",
    defaultMessage: "Inserisci termine",
  },
  glossaryDescription: {
    id: "component.wikiMuseo.glossaryDescription",
    defaultMessage: "Spiegazione",
  },
  glossaryName: {
    id: "component.wikiMuseo.glossaryName",
    defaultMessage: "Termine",
  },
  goToLogin: {
    id: "component.wikiMuseo.goToLogin",
    defaultMessage: "Vai alla Login",
  },
  goToTab: {
    id: "component.wikiMuseo.goToTab",
    defaultMessage: "Vai alla scheda",
  },
  group: {
    id: "component.wikiMuseo.group",
    defaultMessage: "Gruppo",
  },
  guidedTour: {
    id: "component.wikiMuseo.guidedTour",
    defaultMessage: "Con guida",
  },
  inadequateSetup: {
    id: "component.wikiMuseo.inadequateSetup",
    defaultMessage: "• Allestimento non adeguato",
  },
  info: {
    id: "component.wikiMuseo.info",
    defaultMessage: "Info",
  },
  issueCollection: {
    id: "component.wikiMuseo.issueCollection",
    defaultMessage: "Che problemi hai riscontrato?",
  },
  kids: {
    id: "component.wikiMuseo.kids",
    defaultMessage: "Esigenze famigliari / bambini",
  },
  lis: {
    id: "component.wikiMuseo.lis",
    defaultMessage: "Interprete LIS",
  },
  lisVideo: {
    id: "component.wikiMuseo.lisVideo",
    defaultMessage: "Video con interprete LIS",
  },
  maps: {
    id: "component.wikiMuseo.maps",
    defaultMessage: "Mappe",
  },
  maxPeople: {
    id: "component.wikiMuseo.maxPeople",
    defaultMessage: "Numero massimo comitiva",
  },
  meetingPlace: {
    id: "component.wikiMuseo.meetingPlace",
    defaultMessage: "Punto di ritrovo",
  },
  minGroup: {
    id: "component.wikiMuseo.minGroup",
    defaultMessage: "Quota gruppi (minimo {minGroup} persone) su richiesta",
  },
  minVisitors: {
    id: "component.wikiMuseo.minVisitors",
    defaultMessage: "Numero minimo visitatori",
  },
  minPeople: {
    id: "component.wikiMuseo.minPeople",
    defaultMessage: "Numero minimo comitiva",
  },
  mode: {
    id: "component.wikiMuseo.mode",
    defaultMessage: "Modalità",
  },
  model3D: {
    id: "component.wikiMuseo.model3D",
    defaultMessage: "Modello 3D",
  },
  model3DGallery: {
    id: "component.wikiMuseo.model3DGallery",
    defaultMessage: "Galleria Modelli 3D",
  },
  modifyImage: {
    id: "component.wikiMuseo.modifyImage",
    defaultMessage: "Modifica immagine",
  },
  modifyMap: {
    id: "component.wikiMuseo.modifyMap",
    defaultMessage: "Modifica mappa",
  },
  modifyAudio: {
    id: "component.wikiMuseo.modifyAudio",
    defaultMessage: "Modifica audio",
  },
  modifyVideo: {
    id: "component.wikiMuseo.modifyVideo",
    defaultMessage: "Modifica video",
  },
  modify3D: {
    id: "component.wikiMuseo.modify3D",
    defaultMessage: "Modifica modello 3D",
  },
  modifyExternalContent: {
    id: "component.wikiMuseo.modifyExternalContent",
    defaultMessage: "Modifica Contenuto Esterno",
  },
  no3D: {
    id: "component.wikiMuseo.no3D",
    defaultMessage: "Non sono presenti modelli 3D.",
  },
  noAudio: {
    id: "component.wikiMuseo.noAudio",
    defaultMessage: "Non sono presenti audio.",
  },
  noImage: {
    id: "component.wikiMuseo.noImage",
    defaultMessage: "Non sono presenti immagini.",
  },
  noMap: {
    id: "component.wikiMuseo.noMap",
    defaultMessage: "Non sono presenti mappe.",
  },
  noPOIMap: {
    id: "component.wikiMuseo.noPOIMap",
    defaultMessage: "Non sono presenti punti di interesse con coordinate.",
  },
  noVideo: {
    id: "component.wikiMuseo.noVideo",
    defaultMessage: "Non sono presenti video.",
  },
  noExternalContent: {
    id: "component.wikiMuseo.noExternalContent",
    defaultMessage: "Non sono presenti contenuti esterni.",
  },
  oss: {
    id: "component.wikiMuseo.oss",
    defaultMessage: "Assistente personale OSS",
  },
  outdoor: {
    id: "component.wikiMuseo.outdoor",
    defaultMessage: "Comprende attività all'aperto",
  },
  period: {
    id: "component.wikiMuseo.period",
    defaultMessage: "Periodo",
  },
  pointOfInterests: {
    id: "component.wikiMuseo.pointOfInterests",
    defaultMessage: "punti di interesse da visitare",
  },
  poorLighting: {
    id: "component.wikiMuseo.poorLighting",
    defaultMessage: "• Scarsa illuminazione",
  },
  prices: {
    id: "component.wikiMuseo.prices",
    defaultMessage: "Prezzi",
  },
  priceBase: {
    id: "component.wikiMuseo.priceBase",
    defaultMessage: "Prezzo adulto",
  },
  priceCompanion: {
    id: "component.wikiMuseo.priceCompanion",
    defaultMessage: "Accompagnatore persona disabile",
  },
  priceGroup: {
    id: "component.wikiMuseo.priceGroup",
    defaultMessage: "Prezzo comitiva",
  },
  priceReduced: {
    id: "component.wikiMuseo.priceReduced",
    defaultMessage: "Prezzo ridotto (4 - 14 anni)",
  },
  priceRemote: {
    id: "component.wikiMuseo.priceRemote",
    defaultMessage: "Prezzo in collegamento da remoto",
  },
  priority: {
    id: "component.wikiMuseo.priority",
    defaultMessage: "Priorità",
  },
  privateMesssage: {
    id: "component.wikiMuseo.privateMesssage",
    defaultMessage: " Per proseguire occorre autenticarsi",
  },
  privatePoi: {
    id: "component.wikiMuseo.privatePoi",
    defaultMessage:
      "Il punto di interesse è privato e occorre autenticarsi per poterlo vedere",
  },
  privateMessage: {
    id: "component.wikiMuseo.privateMessage",
    defaultMessage: "Per proseguire occorre autenticarsi",
  },
  privateTour: {
    id: "component.wikiMuseo.privateTour",
    defaultMessage:
      "Il tour è privato e occorre autenticarsi per poterlo vedere",
  },
  privateTourAction: {
    id: "component.wikiMuseo.privateTourAction",
    defaultMessage: "Clicca il link sotto per eseguire la login o registrarti",
  },
  question: {
    id: "component.wikiMuseo.question",
    defaultMessage: "Domanda",
  },
  questions: {
    id: "component.wikiMuseo.questions",
    defaultMessage: "Domande",
  },
  questionAdd: {
    id: "component.wikiMuseo.questionAdd",
    defaultMessage: "Aggiungi domanda",
  },
  questionModify: {
    id: "component.wikiMuseo.questionModify",
    defaultMessage: "Modifica domanda",
  },
  questionNew: {
    id: "component.wikiMuseo.questionNew",
    defaultMessage: "Nuova domanda",
  },
  questionSave: {
    id: "component.wikiMuseo.questionSave",
    defaultMessage: "Salva domanda",
  },
  removePoiFromTour: {
    id: "component.wikiMuseo.removePoiFromTour",
    defaultMessage:
      "Questo punto di interesse è presente all'interno di un Tour.",
  },
  results: {
    id: "component.wikiMuseo.results",
    defaultMessage: "Risultati",
  },
  searchNearestTours: {
    id: "component.wikiMuseo.searchNearestTours",
    defaultMessage: "Ricerca percorsi più vicini alla tua posizione",
  },
  services: {
    id: "component.wikiMuseo.services",
    defaultMessage: "Servizi",
  },
  servicesOnDemand: {
    id: "component.wikiMuseo.servicesOnDemand",
    defaultMessage: "Servizi a richiesta",
  },
  support: {
    id: "component.wikiMuseo.support",
    defaultMessage: "Tipo di supporto",
  },
  supportForNeeds: {
    id: "component.wikiMuseo.supportForNeeds",
    defaultMessage: "Supporto per esigenze",
  },
  to: {
    id: "component.wikiMuseo.to",
    defaultMessage: "Al",
  },
  tourWithoutPois: {
    id: "component.wikiMuseo.tourWithoutPois",
    defaultMessage: "Questo percorso non ha Punti di Interesse.",
  },
  transport: {
    id: "component.wikiMuseo.transport",
    defaultMessage: "Trasporto/Modalità di fruizione",
  },
  transportWalk: {
    id: "component.wikiMuseo.transportWalk",
    defaultMessage: "A piedi",
  },
  transportBike: {
    id: "component.wikiMuseo.transportBike",
    defaultMessage: "In bicicletta",
  },
  transportPublic: {
    id: "component.wikiMuseo.transportPublic",
    defaultMessage: "Con mezzi pubblici",
  },
  transportPrivateTransfer: {
    id: "component.wikiMuseo.transportPrivateTransfer",
    defaultMessage: "Con transfer privato",
  },
  transportPrivateCar: {
    id: "component.wikiMuseo.transportPrivateCar",
    defaultMessage: "Con auto propria",
  },
  video: {
    id: "component.wikiMuseo.video",
    defaultMessage: "Video",
  },
  videoGallery: {
    id: "component.wikiMuseo.videoGallery",
    defaultMessage: "Galleria Video",
  },
  viewNearestTours: {
    id: "component.wikiMuseo.viewNearestTours",
    defaultMessage:
      "Se vuoi visualizzare punti di interesse di itinerari vicini a te",
  },
  viewTourPois: {
    id: "component.wikiMuseo.viewTourPois",
    defaultMessage:
      "Stai visualizzando punti di interesse appartenenti all'itinerario",
  },
  welcomeMuseum: {
    id: "component.wikiMuseo.welcomeMuseum",
    defaultMessage: "Cosa vuoi visitare oggi?",
  },
  worksTour: {
    id: "component.wikiMuseo.worksTour",
    defaultMessage: "Opere del percorso",
  },
  welcomeTextdefault: {
    id: "component.welcomeText.default",
    defaultMessage: "Percorsi disponibili",
  },
  welcomeTextlie: {
    id: "component.welcomeText.lie",
    defaultMessage: "Cosa vuoi visitare oggi?",
  },
  welcomeSubTextlie: {
    id: "component.welcomeSubText.lie",
    defaultMessage:
      "Scegli un itinerario guidato tra quelli disponibili. Cerca quello più interessante ed adatto rispetto alle tue preferenze ed esigenze specifiche.",
  },
  welcomeTourslie: {
    id: "component.welcomeTours.lie",
    defaultMessage: "SUGGERITI PER TE",
  },
  welcomeTextmauto: {
    id: "component.welcomeText.lie",
    defaultMessage: "Cosa vuoi visitare oggi?",
  },
  welcomeSubTextmauto: {
    id: "component.welcomeSubText.mauto",
    defaultMessage:
      "Scegli un itinerario guidato tra quelli disponibili. Cerca quello più interessante ed adatto rispetto alle tue preferenze ed esigenze specifiche.",
  },
  welcomeToursmauto: {
    id: "component.welcomeTours.mauto",
    defaultMessage: "SUGGERITI PER TE",
  },
  YTconsent: {
    id: "component.wikiMuseo.YTconsent",
    defaultMessage:
      "Per visionare il contenuto, leggere il consenso e accettarlo",
  },
  YTcontent: {
    id: "component.wikiMuseo.YTcontent",
    defaultMessage: "Contenuto multimediale YouTube",
  },
  videoCookiesTitle: {
    id: "component.wikiMuseo.videoCookiesTitle",
    defaultMessage: "Youtube utilizza i cookie",
  },
  videoCookiesText: {
    id: "component.wikiMuseo.videoCookiesText",
    defaultMessage:
      "Youtube utilizza i cookie per personalizzare contenuti e annunci pubblicitari, fornire funzionalità dei social media e analizzare il nostro traffico. Condividiamo inoltre informazioni sull'utilizzo del nostro sito con i nostri partner di social media, pubblicità e analisi, che possono combinarle con altre informazioni che hai fornito loro o che hanno raccolto dal tuo utilizzo dei loro servizi.",
  },
  school: {
    id: "component.wikiMuseo.school",
    defaultMessage: "Scuola",
  },
  association: {
    id: "component.wikiMuseo.association",
    defaultMessage: "Associazione",
  },
  touristicOperator: {
    id: "component.wikiMuseo.touristicOperator",
    defaultMessage: "Operatore turistico",
  },
  privateGroup: {
    id: "component.wikiMuseo.privateGroup",
    defaultMessage: "Gruppo/singolo privato",
  },
});
