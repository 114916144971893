import { defineMessages } from "react-intl";

export const media = defineMessages({
  cover: {
    id: "component.media.cover",
    defaultMessage: "Copertina",
  },
  image: {
    id: "component.media.image",
    defaultMessage: "Immagine",
  },
  logo: {
    id: "component.media.logo",
    defaultMessage: "Logo",
  },
  thumb: {
    id: "component.media.thumb",
    defaultMessage: "Anteprima",
  },
  openstreetmap: {
    id: "component.media.openstreetmap",
    defaultMessage: "Mappa",
  },
});
