import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";

import { activation, common } from "../../../messages";
import SafeMarkdownView from "../../../components/SafeMarkdownView";
import {
  getConsentKoValue,
  getConsentOkValue,
} from "../../../utils/activationUtils";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    margin: 2,
    width: "100%",
  },
  dialogContent: {
    //padding: "8px 24px",
  },
  dialogActions: {
    //padding: "8px 24px",
  },
}));

export function ConsentCheck({ consent, value, onChange, disabled }) {
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const hasText = !!consent.text?.trim();
  const okValue = getConsentOkValue(consent);
  const koValue = getConsentKoValue(consent);

  const classes = useStyles();

  const handleChange = (event, val) => {
    onChange(consent.id, val);
  };

  return (
    <>
      <FormControl margin="normal" fullWidth>
        {consent.title?.trim() && (
          <Typography variant="h6" gutterBottom>
            {consent.title}
          </Typography>
        )}
        <SafeMarkdownView content={consent.agreement_text} />
        <Box display="flex" justifyContent="space-between">
          {hasText && (
            <Link onClick={() => setOpen(true)}>
              {intl.formatMessage(common.openView)}
            </Link>
          )}
          <Box flexGrow={1} />
          {consent.mandatory && (
            <span>*{intl.formatMessage(common.mandatory)}</span>
          )}
        </Box>
        <RadioGroup
          row
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          <FormControlLabel
            value={okValue}
            control={<Radio required color="primary" />}
            label={intl.formatMessage(activation.agreeTos)}
            disabled={disabled}
          />
          <FormControlLabel
            value={koValue}
            control={<Radio required color="primary" />}
            label={intl.formatMessage(activation.dontAgreeTos)}
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
      <Dialog
        open={hasText && open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent style={{ padding: "4px 4px" }}>
          <SafeMarkdownView content={consent.text || ""} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <FormattedMessage id="common.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
